import type { VehicleSheetOverLoad } from 'models'

type VehicleSheetPropertiesToDisplay = Pick<
  VehicleSheetOverLoad,
  | 'project'
  | 'cardName'
  | 'dossierRefVendeur'
  | 'immatriculation'
  | 'statut'
  | 'sousStatut'
  | 'numeroDeDecompte'
  | 'dateMission'
  | 'dateLivraison'
  | 'dateLivraison2024'
  | 'heure'
  | 'heureE'
  | 'dateVenteAffectee'
  | 'dateVenteAffecteeE'
  | 'numeroOrdre'
  | 'marque'
  | 'typeComm'
  | 'modeleArgus'
  | 'type'
  | 'numeroSerie'
  | 'genre'
  | 'carrosserie'
  | 'couleur'
  | 'suiviEntretien'
  | 'carnet'
  | 'carteCode'
  | 'clefs'
  | 'roueSecoursAbsente'
  | 'batterie'
  | 'courroie'
  | 'finGC'
  | 'certificatImmatriculation'
  | 'suiviCertifImmat'
  | 'places'
  | 'date1MEC'
  | 'energie'
  | 'kilomDeclare'
  | 'kilomReel'
  | 'puissance'
  | 'autoEcole'
  | 'avaries'
  | 'etat'
  | 'noteVisuelle'
  | 'interieur'
  | 'expertise'
  | 'dateDerniereCotation'
  | 'cotePerso'
  | 'coteKm'
  | 'age'
  | 'reserve'
  | 'estimation'
  | 'prixNeuf'
  | 'fre'
  | 'res1'
  | 'prixAdjudication'
  | 'pcentCoteDatee'
  | 'pcentCoteKm'
  | 'opposition'
  | 'procedure'
  | 'tribunal'
  | 'mandataire'
  | 'jugement'
  | 'juge'
  | 'commentaire'
  | 'denonce'
  | 'proprietaireDebiteur'
  | 'coDebiteur'
  | 'caution'
  | 'caution1'
  | 'observationsVendeur'
  | 'equipementsPrint'
  | 'nbJoursStock'
  | 'scanCT'
  | 'scanCG'
  | 'memoExterne'
  | 'urlET'
  | 'codeParcStockage'
  | 'urlBE'
>
type VehicleSheetLabels = Record<keyof VehicleSheetPropertiesToDisplay, string>

const vehicleSheetLabels: VehicleSheetLabels = {
  project: 'Réf.int dossier : ',
  cardName: 'Vendeur : ',
  dossierRefVendeur: 'Réf du vendeur : ',
  immatriculation: 'Immatriculation : ',
  nbJoursStock: 'Nombre de jours : ',
  statut: 'Statut général : ',
  sousStatut: 'Sous-Statut : ',
  numeroDeDecompte: 'N° de décompte : ',
  dateMission: 'Date de mandat : ',
  dateLivraison2024: 'Date de livraison : ',
  dateLivraison: 'Arrivée parc actuel : ',
  heure: 'Heure de vente : ',
  heureE: 'Heure de vente electro: ',
  dateVenteAffectee: 'Date de vente : ',
  dateVenteAffecteeE: 'Date vente électro : ',
  numeroOrdre: 'N° ordre affiché : ',
  marque: 'Marque : ',
  typeComm: 'Type com : ',
  modeleArgus: 'Type argus : ',
  type: 'Type min : ',
  numeroSerie: 'Numéro de série : ',
  genre: 'Genre : ',
  carrosserie: 'Carrosserie : ',
  couleur: 'Couleur : ',
  suiviEntretien: 'Suivi Entretien: ',
  carnet: 'Carnet : ',
  carteCode: 'Carte Code : ',
  clefs: 'Clefs : ',
  roueSecoursAbsente: 'RS absente : ',
  batterie: 'PB Batterie : ',
  courroie: 'Courroie : ',
  scanCG: 'Carte Grise: ',
  scanCT: 'Contrôle Technique: ',
  finGC: 'Fin GC : ',
  certificatImmatriculation: 'Certif Immat : ',
  suiviCertifImmat: 'Suivi certif immat : ',
  places: 'Places : ',
  date1MEC: 'MEC : ',
  energie: 'Energie : ',
  kilomDeclare: 'Km déclaré : ',
  kilomReel: 'Km réel : ',
  puissance: 'Puissance : ',
  autoEcole: 'Auto-école : ',
  avaries: 'Avaries : ',
  etat: 'Etat: ',
  noteVisuelle: 'Note visuelle : ',
  interieur: 'Intérieur : ',
  expertise: 'Expertise : ',
  dateDerniereCotation: 'Cotation le : ',
  cotePerso: 'Cote Datée : ',
  coteKm: 'Cote Km : ',
  age: 'Age : ',
  reserve: 'Réserve : ',
  estimation: 'Estimation : ',
  prixNeuf: 'Neuf : ',
  fre: 'FRE : ',
  res1: 'Réserve-1 : ',
  prixAdjudication: 'Adjugé : ',
  pcentCoteDatee: '%Cote datée : ',
  pcentCoteKm: '%Cote Km : ',
  opposition: 'Opposition : ',
  procedure: 'Procédure : ',
  tribunal: 'Tribunal : ',
  mandataire: 'Mandataire : ',
  jugement: 'Jugement : ',
  juge: 'Juge : ',
  commentaire: 'Commentaire : ',
  denonce: 'Dénonce vente : ',
  proprietaireDebiteur: 'Débiteur : ',
  coDebiteur: 'Co débiteur : ',
  caution: 'Caution : ',
  caution1: 'Caution 1 : ',
  observationsVendeur: 'Observations:',
  equipementsPrint: 'Équipements : ',
  memoExterne: 'Memo: ',
  urlET: 'Éclaté Carrosserie: ',
  codeParcStockage: 'Parc Stockage: ',
  urlBE: 'Bilan Expert: ',
}

export { vehicleSheetLabels }
export type { VehicleSheetLabels }
