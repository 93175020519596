import {
  FutureSaleDTO,
  FutureSaleVehicleDTO,
  HistoryDTO,
  SaleResultDTO,
  SaleResultVehicleDTO,
  SellerDTO,
  StockVehicleDTO,
  VehicleSheetDTO,
  WaitingFileVehicleDTO,
  dummyHistoryResponse,
  familyAllSellersCardName,
} from 'models'
import { msalAxiosInstance } from 'api'
import { ENDPOINTS, Endpoints } from 'utils/constants'
import dayjs from 'dayjs'
import axios from 'axios'
import { ApiPhotosResponse, IQueries, UpdateVehicleReserveAxiosResponse } from './queryInterface'
import { OdataFilterBuilder } from '../filters/OdataFilterBuilder'

const queryAdapter: IQueries = {
  getFutureSales: async (seller: SellerDTO) => {
    const futureSalesEndpoint =
      seller.cardName === familyAllSellersCardName
        ? ENDPOINTS.FUTURE_SALES_BY_FAMILY
        : ENDPOINTS.FUTURE_SALES
    const odataFilter = new OdataFilterBuilder().addCode(seller).build()

    const { data: futureSales } = await msalAxiosInstance.get<Array<FutureSaleDTO>>(
      `${futureSalesEndpoint}?${odataFilter}`,
    )

    return futureSales
  },
  getFutureSaleVehicles: async (seller: SellerDTO, saleId: string, isSaleOnline: boolean) => {
    const saleIdFilter = `DocEntryVente${isSaleOnline ? 'E' : ''} eq ${saleId}`

    const odataFilter = new OdataFilterBuilder().addCode(seller).and(saleIdFilter).build()

    const { data: futureSaleVehicles } = await msalAxiosInstance.get<Array<FutureSaleVehicleDTO>>(
      `${ENDPOINTS.FUTURE_SALE_VEHICLES}?${odataFilter}`,
    )

    return futureSaleVehicles
  },
  getSalesResult: async (seller: SellerDTO) => {
    const salesResultEndpoint =
      seller.cardName === familyAllSellersCardName
        ? ENDPOINTS.SALES_RESULT_BY_FAMILY
        : ENDPOINTS.SALES_RESULT

    const odataOrderby = '$orderby=DateVente desc'
    const startDate = dayjs().add(-6, 'month').format('YYYY-MM-DD')
    const dateFilter = `DateVente gt '${startDate}'`

    const odataFilter = new OdataFilterBuilder().addCode(seller).and(dateFilter).build()

    const { data: salesResult } = await msalAxiosInstance.get<Array<SaleResultDTO>>(
      `${salesResultEndpoint}?${odataOrderby}&${odataFilter}`,
    )

    return salesResult
  },
  getSaleResultVehicles: async (seller: SellerDTO, saleId: string) => {
    const saleIdFilter = `DocEntryVente eq ${saleId}`

    const odataFilter = new OdataFilterBuilder().addCode(seller).and(saleIdFilter).build()

    const { data: saleResultVehicles } = await msalAxiosInstance.get<Array<SaleResultVehicleDTO>>(
      `${ENDPOINTS.SALE_RESULT_VEHICLES}?${odataFilter}`,
    )

    return saleResultVehicles
  },
  getStock: async (seller: SellerDTO) => {
    const limitCreationDate = dayjs().add(-401, 'days').format('YYYY-MM-DD')
    const creationDateFilter = `DateCreation gt datetime'${limitCreationDate}'`
    const unsoldStatusFilter = "tolower(Statut) ne 'vendu'"

    const odataFilter = new OdataFilterBuilder()
      .addCode(seller)
      .and(creationDateFilter)
      .and(unsoldStatusFilter)
      .build()

    const { data: stock } = await msalAxiosInstance.get<Array<StockVehicleDTO>>(
      `${ENDPOINTS.STOCK}?${odataFilter}`,
    )

    return stock
  },
  getWaitingFiles: async (seller: SellerDTO) => {
    const limitCreationDate = dayjs().add(-1201, 'days').format('YYYY-MM-DD')
    const creationDateFilter = `DateCreation gt datetime'${limitCreationDate}'`

    const unsoldStatusFilter = "tolower(Statut) ne 'vendu'"

    const emptyStatusFilter = "(Statut ne '' and Statut ne null)"

    const emptySubStatusFilter = "(SousStatut ne '' and SousStatut ne null)"

    const goodForSaleFilter =
      "(tolower(SousStatut) ne 'bon à vendre' and tolower(SousStatut) ne 'bon a vendre')"

    const statusAndSubStatusFilter = `(${emptyStatusFilter} or ${emptySubStatusFilter})`

    const odataFilter = new OdataFilterBuilder()
      .addCode(seller)
      .and(creationDateFilter)
      .and(unsoldStatusFilter)
      .and(statusAndSubStatusFilter)
      .and(goodForSaleFilter)
      .build()

    const { data: stock } = await msalAxiosInstance.get<Array<WaitingFileVehicleDTO>>(
      `${ENDPOINTS.STOCK}?${odataFilter}`,
    )
    return stock
  },
  getVehicle: async (vehicleId: string) => {
    const projectFilter = `Project eq '${vehicleId}'`

    const odataFilter = new OdataFilterBuilder().add(projectFilter).build()

    const { data: vehicles } = await msalAxiosInstance.get<Array<VehicleSheetDTO>>(
      `${ENDPOINTS.VEHICLE}?${odataFilter}`,
    )

    return vehicles?.[0]
  },
  getVehicleByImmat: async (vehicleImmat: string) => {
    const immatFilter = `Immatriculation eq '${vehicleImmat}'`

    const odataFilter = new OdataFilterBuilder().add(immatFilter).build()

    const { data: vehicles } = await msalAxiosInstance.get<Array<VehicleSheetDTO>>(
      `${ENDPOINTS.VEHICLE}?${odataFilter}`,
    )

    // Return several vehicles?
    return vehicles
  },

  getSellersByFamily: async (familyCardCode: string) => {
    const odataFilter = new OdataFilterBuilder()
      .add(`CardCodeFamille eq '${familyCardCode}'`)
      .and("not substringof('ACPTE',CardName)")
      .and("Inactif ne 'Y'")
      .build()

    const odataOrderby = '$orderby=CardName asc'

    const { data: sellers } = await msalAxiosInstance.get<Array<SellerDTO>>(
      `${ENDPOINTS.SELLERS}?${odataOrderby}&${odataFilter}`,
    )

    return sellers
  },

  getCurrentSeller: async () => {
    const { data: seller } = await msalAxiosInstance.get<Array<SellerDTO>>(ENDPOINTS.CURRENT_SELLER)

    return seller?.[0]
  },

  getOneSellerFromEachFamily: async () => {
    const odataFilter = new OdataFilterBuilder()
      .add("not substringof('ACPTE',CardNameFamille)")
      .build()

    const odataOrderby = '$orderby=CardNameFamille asc'

    const { data: allSellerFamilies } = await msalAxiosInstance.get<Array<SellerDTO>>(
      `${ENDPOINTS.ALL_FAMILIES}?${odataOrderby}&${odataFilter}`,
    )

    return allSellerFamilies
  },

  updateVehicleReserve: async ({ vehicleProject, newReserve, cardCodeFamille }) => {
    const response = await msalAxiosInstance.put<
      Endpoints,
      UpdateVehicleReserveAxiosResponse,
      {
        numDossier: string
        reserve: string
        cardCodeFamille: string
      }
    >(ENDPOINTS.VEHICLE, {
      numDossier: vehicleProject,
      reserve: newReserve.toString(),
      cardCodeFamille,
    })

    return response
  },
  getPhotos: async (immat: string) => {
    const { data: photos } = await axios.post<ApiPhotosResponse>(
      ENDPOINTS.VEHICLE_PHOTOS,
      {
        immat,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    return photos
  },

  getHistory: async (historyParams: string) => {
    const endpoint = `${ENDPOINTS.HISTORY}${historyParams}`

    const { data: history } = await msalAxiosInstance.get<Array<HistoryDTO>>(endpoint)

    return history
  },
}

export { queryAdapter }
